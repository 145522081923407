import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c(VRow,[_c(VCol,[_c('h1',{staticStyle:{"color":"#455a64","font-weight":"400","font-size":"1.9rem"}},[_vm._v(" Peer Reviewed Journals ")])]),_c(VCol,{staticClass:"text-right"},[_c('close-window')],1)],1),_c('div',[_c(VExpansionPanels,{staticClass:"mb-6",attrs:{"focusable":""},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},_vm._l((_vm.mainDataList),function(item,i){return _c(VExpansionPanel,{key:i},[_c(VExpansionPanelHeader,{attrs:{"expand-icon":"mdi-menu-down"}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c(VExpansionPanelContent,[_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("About")]),_c(VListItemSubtitle,{staticClass:"text-wrap"},[_vm._v(" "+_vm._s(item.about)+" ")])],1)],1),_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Peer Review")]),_c(VListItemSubtitle,{staticClass:"text-wrap"},[_vm._v(_vm._s(item.peer))])],1)],1),_c(VListItem,{attrs:{"two-line":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","sm":"8"}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Website")]),_c(VListItemSubtitle,[_c('a',{attrs:{"target":"_blank"},on:{"click":function($event){return _vm.newWindow(item.website)}}},[_vm._v(" "+_vm._s(_vm.removeHttp(item.website))+" ")])])],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"2"}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Founded")]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.founded))])],1)],1),_c(VCol,{attrs:{"cols":"12","sm":"2"}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Impact Factor")]),_c(VListItemSubtitle,[_vm._v(_vm._s(item.impact))])],1)],1)],1)],1)],1)],1)}),1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }